import type { MonoTypeOperatorFunction, Observable } from 'rxjs'
import { buffer, filter, fromEvent, interval, merge, startWith, switchMap } from 'rxjs'

export function emitOnNth<T>(n: number): MonoTypeOperatorFunction<T> {
  return filter((_, index) => n === index)
}

// Create a buffer that executes every 10_000 ms and every time a session-ending event occurs
// x--10--10-x--10-x-x--10--10--10--10
// Where every x is a danger event and every 10 is an interval elapsing
// RestartBufferInterval can emit any value. This doesn't care what it is, because it destroys it
export function makeIntervalBufferer<T>(restartBufferInterval$: Observable<any>, i: number) {
  return buffer<T>(restartBufferInterval$.pipe(switchMap(() => interval(i).pipe(startWith(0)))))
}

export function makeBufferedObservable<T>(recorder$: Observable<T>, sessionEnding$: Observable<any>, nth: number = 4) {
  const startBuffering$ = recorder$.pipe(emitOnNth(nth))

  // Emit buffer every time it looks like the session is ending
  const restartBufferInterval$ = merge(startBuffering$, sessionEnding$)

  // Link up the bufferer to the recorder
  // Buffer every 5 seconds (maybe this should be a constant!)
  const bufferedRecorder$ = recorder$
    .pipe(makeIntervalBufferer(restartBufferInterval$, 5000))
  return bufferedRecorder$
}

export function makeSessionEndingObservable(window: Window) {
  // this "merge" observable combines the three events and emits when any of them fire
  const beforeUnload$ = fromEvent(window, 'beforeunload')
  const visibilityChange$ = fromEvent(
    window.document,
    'visibilitychange',
  ).pipe(filter(() => document.visibilityState === 'hidden'))
  const blur$ = fromEvent(window, 'blur')
  const sessionEnding$ = merge(beforeUnload$, visibilityChange$, blur$)
  return sessionEnding$
}
