// Handle page switching inside a single-page app
export function handlePageSwitching(window: Window) {
  let lastUri = location.href
  const pushState = window.history.pushState
  window.history.pushState = (...args) => {
    const result = pushState.apply(history, args)
    if (lastUri !== location.href) {
      lastUri = location.href
      window.dispatchEvent(new Event('locationchange'))
    }
    return result
  }

  const replaceState = history.replaceState
  window.history.replaceState = (...args) => {
    const result = replaceState.apply(history, args)
    if (lastUri !== location.href) {
      lastUri = location.href
      window.dispatchEvent(new Event('locationchange'))
    }
    return result
  }

  window.addEventListener('popstate', () => {
    if (lastUri !== location.href) {
      lastUri = location.href
      window.dispatchEvent(new Event('locationchange'))
    }
  })

  const headMutationObserver = new MutationObserver(() => {
    if (lastUri !== location.href) {
      lastUri = location.href
      window.dispatchEvent(new Event('locationchange'))
    }
  })
  headMutationObserver.observe(document.head, { childList: true })
}
