import type { DOMSerializer } from '@/util/domSerializer'
import type {
  RecordingEventByActionType,
} from '../types'

import { concatAll, map, Observable } from 'rxjs'
import { ActionType } from '../types'

// Helper function to create an rxjs observable from a mutation observer
export function fromMutation(element: HTMLElement, options?: MutationObserverInit): Observable<MutationRecord[]> {
  return new Observable((subscriber) => {
    const observer = new MutationObserver((mutationsList, _) => {
      subscriber.next(mutationsList)
    })
    observer.observe(element, options)
    return () => observer.disconnect()
  })
}

export function createMutationObservable({ domSerializer, document }: { domSerializer: DOMSerializer, document: Document }) {
  // This needs to be as performant as possible, hence the hand-optimized loop
  // and the separation from the other things
  function handleMutationsList(mutationsList: MutationRecord[]) {
    const events: Omit<RecordingEventByActionType<ActionType.MUTATION_EVENT>, 'timestamp'>[] = []
    for (const mutation of mutationsList) {
      if (mutation.target instanceof Element) {
        events.push({
          type: ActionType.MUTATION_EVENT,
          data: domSerializer.serializeMutation(mutation),
        })
      }
    }
    return events
  }

  // This has to be separated out from the rest because it's weird
  const bodyMutationObserver$ = fromMutation(
    document.body, // some observed element
    { childList: true, attributes: true, subtree: true },
  ).pipe(map(handleMutationsList), concatAll()) // concatAll has an undocumented ability to take just a plain ol array (see https://stackoverflow.com/questions/41092488/rxjs-json-data-with-an-array-processing-each-item-further-in-the-stream)
  return bodyMutationObserver$
}
