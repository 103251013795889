// import { createHash } from 'node:crypto' // WARNING: STANDARD LIB FROM NODE IMPORT. HERE BECAUSE OTHER LIBS (AB)USE IT
import type { OperatorFunction } from 'rxjs'

import { interval, map, pairwise, pipe, throttle } from 'rxjs'

// Standard function to get timestamp because there are many different ways to get the timestamp and we want it to be consistent across the project
export function getTimestamp() {
  return Date.now()
  // as opposed to performance.now() /* - creationTimestamp */ + startTimestamp this is actually FASTER!
  // we want the epoch because it is locale-independent, portable, and easy to store as an integer
  // other side can handle tz data
}

// Helper function to simplify point chains
// If points are close together in time and in space, we can simplify them
// This "throttles" incoming points more if they are close together
// We are willing to accept this lower resolution for points that are close together because nobody cares about these 1mm movements
export function simplifyPointsWithTimeout<T extends Point>(maxTimeout: number): OperatorFunction<T, T> {
  return pipe(
    pairwise(),
    throttle(([prev, curr]) => {
      const d = distance(prev, curr)
      const timeout = Math.min(maxTimeout / d, maxTimeout)
      return interval(timeout)
    }),
    map(([_, curr]) => curr),
  )
}

// Geometry helpers
export interface Point {
  x: number
  y: number
}
export function distance(a: Point, b: Point) {
  const x = a.x - b.x
  const y = a.y - b.y
  return (x * x + y * y) ** 0.5
}
