export interface Counter {
  increment: () => number
  decrement: () => number
  getCount: () => number
}

export function counter(): Counter {
  let count = 0
  return {
    increment() {
      return count++
    },
    decrement() {
      return count--
    },
    getCount() {
      return count
    },
  }
}
