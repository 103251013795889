export const STREAM_NAME = import.meta.env.STREAM_NAME || import.meta.env.VITE_STREAM_NAME
export const STREAM_REGION = import.meta.env.STREAM_REGION || import.meta.env.VITE_STREAM_REGION
export const IDENTITY_POOL_ID
  = import.meta.env.IDENTITY_POOL_ID || import.meta.env.VITE_IDENTITY_POOL_ID
export const REST_API_ENDPOINT
  = import.meta.env.REST_API_ENDPOINT || import.meta.env.VITE_REST_API_ENDPOINT
export const GEOLOCATE_API_ENDPOINT
  = import.meta.env.GEOLOCATE_API_ENDPOINT || import.meta.env.VITE_GEOLOCATE_API_ENDPOINT

// New session ID every 5 minutes
export const SESSION_TIMEOUT_MS = 60_000 * 5

// Break up messages to kinesis in 1MB chunks
export const MAX_CHUNK_SIZE = 1e6
