import type { UnionToIntersection } from '@/types/util'
import { logger } from './logging'

export function identity<T>(x: T): T {
  return x
}

export function inspect<T>(x: T, transform: (x: T) => any = identity) {
  logger.debug(transform(x))
  return x
}

export function merge<T extends object[]>(...args: T): UnionToIntersection<T[number]> {
  return Object.assign({}, ...args)
}

export function partial(fn: (...args: any[]) => any, ...args: any[]) {
  return (...args2: any[]) => fn(...args, ...args2)
}

export function mapOnKey(key: string, fn: (x: any) => any) {
  return (x: any) => {
    if (key in x)
      x[key] = fn(x[key])
    return x
  }
}

type EmptyObject = Record<PropertyKey, never>
// The `& Record<any, unknown>` is necessary to make sure objects without the index key are still allowed
type ReturnOfKey<
  Element,
  Key extends string,
  Arg,
> = Element extends { [_P in Key]: (arg: Arg) => infer R }
  ? R
  : EmptyObject

export function runAtIndex<
  Key extends string,
  Arg,
  Arr extends Array<unknown>, // or readonly unknown[]
>(
  arr: Arr,
  key: Key,
  arg: Arg
): {
  [I in keyof Arr]: ReturnOfKey<Arr[I], Key, Arg>
}
export function runAtIndex<const ARG, const INDEX extends string, FROM extends readonly ({ [_key in INDEX]?: (a: ARG) => unknown } & Record<any, unknown>)[]>(from: FROM, index: INDEX, arg: ARG) {
  return from.map(i => i[index] ? i[index](arg) : {}) as
    { [key in keyof FROM]: FROM[key][INDEX] extends (a: ARG) => infer R ? R : EmptyObject }
}
