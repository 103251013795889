import type { PushArguments } from '@/types/network'
import { generate } from 'xksuid'
import { makeSortableId, type SortableId } from '../util/id'
import { logger } from '../util/logging'

function isPushObject(x: any): x is { pushArgs: any, payload: any } {
  return x && typeof x === 'object' && 'pushArgs' in x && 'payload' in x
}

export class PushQueue<MinimumPushArguments extends Partial<PushArguments> & Pick<PushArguments, 'key'>, Payload> {
  counter: number = 0
  entropy: string
  constructor(
    private prefix: string,
    private sendObject: (pushArgs: MinimumPushArguments, payload: Payload, pushId: string) => Promise<{ success: boolean }>, // ensure this function is idempotent on the server side
    private validatePayload: (payload: any) => payload is Payload = (x): x is Payload => true,
    private maxRetries = 4,
  ) {
    this.entropy = generate()
  }

  storageKey(pushArgs: Pick<PushArguments, 'key'>) {
    return `_n_pending_${this.prefix}_${pushArgs.key}`
  }

  readStorage(pushArgs: Pick<PushArguments, 'key'>) {
    return JSON.parse(sessionStorage.getItem(this.storageKey(pushArgs)) || '{}')
  }

  writeStorage(pushArgs: Pick<PushArguments, 'key'>, value: any) {
    sessionStorage.setItem(this.storageKey(pushArgs), JSON.stringify(value))
  }

  clearStorage(pushArgs: Pick<PushArguments, 'key'>) {
    sessionStorage.removeItem(this.storageKey(pushArgs))
  }

  getAll(pushArgs: Pick<PushArguments, 'key'>): Record<SortableId, {
    pushArgs: MinimumPushArguments
    payload: Payload
    metadata: {
      lastTimestamp: number
      retries: number
    }
  }> {
    const pendingPushes = this.readStorage(pushArgs)
    try {
      if (Object.values(pendingPushes).some((x: any) => !this.validatePayload(x))) {
        const cleanedObject = Object.fromEntries(Object.entries(pendingPushes).filter(([, x]) => isPushObject(x) && x.pushArgs?.key && this.validatePayload(x))) as any
        this.writeStorage(pushArgs, cleanedObject)
        return cleanedObject
      }
    }
    catch (e) {
      logger.error('Error cleaning up DLQ', e)
      this.clearStorage(pushArgs)
      return {}
    }
    return pendingPushes
  }

  generateId() {
    return makeSortableId(Date.now(), `${this.entropy}#${this.counter++}`)
  }

  enqueue(pushArgs: MinimumPushArguments, payload: Payload, id: string = this.generateId()) {
    const existing = this.getAll(pushArgs)
    const previousRetries = id in existing ? existing[id as keyof typeof existing].metadata.retries : 0
    if (previousRetries >= this.maxRetries)
      return null // return null if max retries exceeded

    this.writeStorage(pushArgs, {
      [id]: {
        pushArgs,
        payload,
        metadata: {
          lastTimestamp: Date.now(),
          retries: previousRetries + 1, // increment retries
        },
      },
      ...existing,
    })
    return id
  }

  dequeue(pushArgs: Pick<PushArguments, 'key'>, id: string) {
    this.writeStorage(
      pushArgs,
      { ...this.getAll(pushArgs), [id]: undefined },
    )
  }

  async push(pushArgs: MinimumPushArguments, payload: Payload) {
    logger.debug(`Pushing ${this.prefix}`, payload)

    // Ignore empty payloads
    if (!payload || (Array.isArray(payload) && payload.length === 0)) // stability: not all payloads will be arrays hmm
      return { success: true } // treat it as a success because nothing needed to be pushed so we're done

    const pushId = this.enqueue(pushArgs, payload)
    if (pushId !== null) { // not max retries exceeded
      try {
        const { success } = await this.sendObject(pushArgs, payload, pushId)
        if (success)
          this.dequeue(pushArgs, pushId)
        return { success }
      }
      catch (_) {
        return { success: false }
      }
    }
    return { success: false }
  }

  flush(pushArgs: Pick<PushArguments, 'key'>) {
    (Object.entries(this.getAll(pushArgs))).forEach(
      ([_, value]) => {
        this.push(value.pushArgs, value.payload as Payload)
      },
    )
  }
}
