import type { CodelessEvent } from '@/modules/engine/events'
import type { PushArguments } from '@/types/network'
import { REST_API_ENDPOINT } from '@/const'
import { PushQueue } from './queue'

type FinishedEvent = CodelessEvent & { chosenTests?: { engineSk: string, chosenTest: string }[] }

// function generateEventPushId() {
//   return makeSortableId(Date.now(), crypto.randomUUID())
// }

export const queue = new PushQueue<Pick<PushArguments, 'pageUrl' | 'sessionId' | 'key'>, FinishedEvent[]>('_n_pending_codelessEvents', async (pushArgs: Pick<PushArguments, 'pageUrl' | 'sessionId' | 'key'>, payload: FinishedEvent[]) => {
  try {
    await fetch(new URL(`public/events/${pushArgs.key}?${new URLSearchParams(pushArgs)}`, REST_API_ENDPOINT), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      // keepalive: true,
    })
    return { success: true }
  }
  catch (e) {
    return { success: false }
  }
}, (x): x is FinishedEvent[] => Array.isArray(x))

export const push = queue.push.bind(queue)

// export function pushCodelessEvents(pushArgs: Pick<PushArguments, 'pageUrl' | 'sessionId' | 'key'>, events: FinishedEvent[], generatePushId: () => SortableId = generateEventPushId) {
//   logger.debug('Pushing codeless events')
//   logger.debug(events)
//   if (events.length === 0)
//     return

//   const pushId = generatePushId()
//   codelessEventsDLQ.enqueue(pushArgs, events, pushId)
//   fetch(new URL(`public/events/${pushArgs.key}?${new URLSearchParams(pushArgs)}`, REST_API_ENDPOINT), {
//     method: 'POST',
//     body: JSON.stringify(events),
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//     },
//     // keepalive: true,
//   }).then(() => {
//     codelessEventsDLQ.dequeue(pushArgs, pushId)
//   })
// }

// // Queueing logic
// export function getEventQueue({ key }: Pick<PushArguments, 'key'>): Record<
//   SortableId,
//   {
//     pushArgs: Pick<PushArguments, 'key' | 'pageUrl' | 'sessionId'>
//     payload: FinishedEvent[]
//   }
// > {
//   const pendingPushes = JSON.parse(sessionStorage.getItem(`_n_pendingEventPushes_${key}`) || '{}')
//   // Validate the pending pushes
//   if (Object.values(pendingPushes).some((x: any) => !x || !x.pushArgs || !x.payload)) {
//     sessionStorage.removeItem(`_n_pendingEventPushes_${key}`) // why do we use sessionStorage here?
//     return {}
//   }

//   return pendingPushes
// }

// export function enqueueEvent(
//   pushArgs: Pick<PushArguments, 'pageUrl' | 'sessionId' | 'key'>,
//   events: FinishedEvent[],
//   id: string,
// ) {
//   sessionStorage.setItem(
//     '_n_pendingEventPushes',
//     JSON.stringify({ [id]: { pushArgs, payload: events }, ...getEventQueue(pushArgs) }),
//   )
// }

// export function dequeueEvent(pushArgs: Pick<PushArguments, 'key'>, id: string) {
//   sessionStorage.setItem(
//     '_n_pendingEventPushes',
//     JSON.stringify({ ...getEventQueue(pushArgs), [id]: undefined }),
//   ) // simple shorthand to remove a key immutably
// }

// export function flushEventQueue(pushArgs: Pick<PushArguments, 'key'>) {
//   (Object.entries(getEventQueue(pushArgs)) as [SortableId, { pushArgs: Pick<PushArguments, 'key' | 'pageUrl' | 'sessionId'>, payload: FinishedEvent[] }][]).forEach(
//     ([pendingPushId, value]) => {
//       pushCodelessEvents(value.pushArgs, value.payload, () => pendingPushId)
//     },
//   )
// }
