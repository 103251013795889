import type { CodelessEvent } from './events'
import { map } from 'rxjs'
import { getElementWaiter } from '../../elementWaiter'

interface EngineEdit {
  name: string
  selector: string
  type: 'text' | 'image' | 'image.srcset' | 'style'
  value: string
}

interface EngineTest {
  name: string
  id: string
  edits: EngineEdit[]
  control: boolean
  metric: number
  count: number
}

interface Engine {
  sitePk: string
  engineSk: string
  name: string
  pageUrl: string
  tests: EngineTest[]
  metricId: string
  metricType: 'event' | 'integration'
  status?: 'running' | 'stopped'
}

const IMAGE_UPLOAD_BUCKET_NAME = import.meta.env.IMAGE_UPLOAD_BUCKET_NAME || import.meta.env.VITE_IMAGE_UPLOAD_BUCKET_NAME

// Only count when the engine's page is visited
export interface ActiveEngine { // stupid naming but idk what else to call this hahaha
  sessionId: string
  metricId: string
  metricType: 'event' | 'integration'
  engineSk: string
  chosenTest: string
}

export const engineGlobals: { activeEngines: ActiveEngine[] } = {
  activeEngines: [],
}

export interface EngineCodelessEvent extends CodelessEvent {
  chosenTests?: { engineSk: string, chosenTest: string }[]
}

// This is set for more than A and B but usually we'll just have A and B
function chooseTest({ tests }: Engine) {
  // const visitorId = makeVisitorID()
  const test = tests[Math.floor(Math.random() * tests.length)]
  return test
}

// function cookieSafeBtoa(s: string) {
//   return btoa(s).replaceAll('=', '$')
// }
// function cookieSafeAtob(s: string) {
//   return atob(s.replaceAll('$', '='))
// }

// Initialize the engine for the current session
// function makeEngineTest(a: any, b: any) {
//   return {
//     data: {
//       eventListeners: [{
//         organization: 'organization#2i8YiXplDslHGMjHYVYdlt8n9xF',
//         site: 'site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         url: 'http://localhost:5173/',
//         name: 'Two-Toned Landing Click',
//         selectors: [{ selector: 'img', name: 'Default click', events: ['click'] }],
//         description: '',
//         status: 'active',
//         startDate: '2024-05-01T01:22:47.070Z',
//         pk: '/organization#2i8YiXplDslHGMjHYVYdlt8n9xF/site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         sk: 'eventListener#active#http://localhost:5173/#2fquXKLtiE9JLfn3NGaZ8hcAukP',
//         created: '2024-05-01T01:22:47.070Z',
//         modified: '2024-11-18T19:49:36.060Z',
//       }],
//       engines: [{
//         organization: 'organization#2i8YiXplDslHGMjHYVYdlt8n9xF',
//         site: 'site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         url: 'http://localhost:5173/',
//         startDate: '2024-11-23',
//         endDate: '',
//         status: 'active',
//         name: 'asfdasfas',
//         description: 'asfasfasf',
//         metric: { type: 'codelessEvent', id: '/organization#2i8YiXplDslHGMjHYVYdlt8n9xF/site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3/eventListener#active#http://localhost:5173/#2fquXKLtiE9JLfn3NGaZ8hcAukP' },
//         tests: [
//           { name: 'B', control: false, views: 0, metric: 0, edits: [...(Array.isArray(b) ? b : [b])] },
//           { name: 'A', control: true, views: 0, metric: 0, edits: [...(Array.isArray(a) ? a : [a])] },
//         ],
//         pk: '/organization#2i8YiXplDslHGMjHYVYdlt8n9xF/site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         sk: 'engine#active#http://localhost:5173#2pEtO8sKITmotF0WAV6wOmAYm2j',
//         created: '2024-11-23T06:23:47.024Z',
//         modified: '2024-11-23T06:23:47.024Z',
//       }],
//     },
//   }
// }

export function initEngine(sessionId: string, engines: Engine[]) {
  // const a = 'color:gray'
  // const b = 'color: red; background-color: yellow;'

  // engines = makeEngineTest({ type: 'style', selector: 'h1', name: 'Style H1', value: a }, { type: 'style', selector: 'h1', name: 'Style H1', value: b }).data.engines
  // const visitorId = makeVisitorID()

  // TODO: make this less WET
  // const savedActiveEngines = window.document.cookie.split('; ').filter(row => row.startsWith('engine-st-')).map(row => row.split('=')).map(([key, base64]) => {
  const savedActiveEngines = Object.entries(localStorage).filter(([key]) => key.startsWith('engine-st-')).map(([key, value]) => {
    const engineSessionId = key.split('-')[2]
    const activeEngine: ActiveEngine = { sessionId: engineSessionId, ...JSON.parse(value) }
    // if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well
    //   // codelessEventEngineTestMap[metricId] ??= new Set()
    //   // codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })

    return activeEngine
  })

  // Loop over all the engines that are not stopped and apply them
  engineGlobals.activeEngines = savedActiveEngines

  // const localStorageEntry = {}
  for (const engine of engines.filter(engine => engine.status !== 'stopped')) {
    const test = chooseTest(engine)

    const { engineSk, metricId, metricType } = engine
    const chosenTest = { metricId, metricType, engineSk, chosenTest: test.id, sessionId }
    // window.document.cookie = `engine-st-${sessionId}-${cookieSafeBtoa(engineSk)}=${btoa(JSON.stringify(chosenTest))};max-age=1200` // the engine expires after this time
    localStorage.setItem(`engine-st-${sessionId}-${engineSk}`, JSON.stringify(chosenTest))

    engineGlobals.activeEngines.push(chosenTest)
    console.log('Chose test', test)

    if (test.control)
      continue
    for (const edit of test.edits) {
      getElementWaiter().waitFor(edit.selector).subscribe(($element) => {
        // console.log('Found element', $element, 'for selector', edit.selector, 'and type', edit.type)
        switch (edit.type) {
          case 'text':
            $element.textContent = edit.value
            break
          case 'image': {
            if (/^https?:\/\//.test(edit.value)) {
              $element.setAttribute('src', edit.value)
              break
            }
            const bucketUrl = `https://${IMAGE_UPLOAD_BUCKET_NAME}.s3.amazonaws.com/${edit.value.split('/').map(encodeURIComponent).join('/')}`
            $element.setAttribute('src', bucketUrl)
            break
          }
          case 'image.srcset': {
            if (/^https?:\/\//.test(edit.value)) {
              $element.setAttribute('srcset', edit.value)
              break
            }
            const bucketUrl = `https://${IMAGE_UPLOAD_BUCKET_NAME}.s3.amazonaws.com/${edit.value.split('/').map(encodeURIComponent).join('/')}`
            $element.setAttribute('srcset', bucketUrl)
            break
          }
          case 'style':
            $element.setAttribute('style', edit.value)
            break
        }
      })
    }
  }
}

// This nasty little function is a transformer for codeless events that adds engine information to them
export function createEngineMapper(sessionId: string) {
  // Loop over every engine cookie
  // const engineCookies = window.document.cookie.split('; ').filter(row => row.startsWith('engine-st-')).map(row => row.split('='))
  const codelessEventEngineTestMap: Record<string, Set<{ engineSk: string, chosenTest: string }>> = {}
  // for (const [key, base64] of engineCookies) {
  //   const engineSessionId = key.split('-')[2]
  //   const { engineSk, metricId, metricType, chosenTest } = JSON.parse(atob(base64))
  //   if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well. we only allow this within one session
  //     codelessEventEngineTestMap[metricId] ??= new Set()
  //     codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })
  //   }
  // }

  for (const activeEngine of engineGlobals.activeEngines) {
    const { engineSk, metricId, metricType, chosenTest, sessionId: engineSessionId } = activeEngine
    if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well. we only allow this within one session
      codelessEventEngineTestMap[metricId] ??= new Set()
      codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })
    }
  }
  return map((event: CodelessEvent): EngineCodelessEvent => {
    const chosenTests = codelessEventEngineTestMap[event.eventListenerSk]
    if (chosenTests)
      return { ...event, chosenTests: [...chosenTests] }
    return event
  })
}
