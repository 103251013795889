export function pruneLocalStorageKeys(remove: (key: string) => boolean) {
  const keys = Object.keys(localStorage)
  for (const key of keys) {
    if (remove(key))
      localStorage.removeItem(key)
  }
}

export function cleanOldIds(key: string, sessionId: string) {
  pruneLocalStorageKeys(
    i =>
      (i.startsWith('_n_id_')
        && i.replace('_n_id_', '') !== key)
      || (i.startsWith('_n_last_visit')
        && i.replace('_n_last_visit', '') !== key)
      || (i.startsWith('_n_page_id_')
        && i.replace('_n_page_id_', '') !== sessionId),
  )
}

export function getLastVisit(key: string) {
  const savedLastVisit = localStorage.getItem(
    `_n_last_visit_${key}`,
  )
  return savedLastVisit
    ? new Date(savedLastVisit)
    : undefined
}

// TODO: change this from last visit to last activity
export function saveLastVisit(key: string) {
  localStorage.setItem(`_n_last_visit_${key}`, new Date().toISOString())
}
