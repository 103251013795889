import type { PushArguments } from '@/types/network'
import type { RecordingSetup } from '@/types/recorder'
import { REST_API_ENDPOINT } from '../const'
import { PushQueue } from './queue'

export async function pushSetup(pushArgs: Pick<PushArguments, 'key' | 'token' | 'sessionId' | 'pageId'>, setup: RecordingSetup) {
  try {
    await fetch(
      new URL(
        `public/setup?${new URLSearchParams(pushArgs)}`,
        REST_API_ENDPOINT,
      ),
      {
        method: 'POST',
        body: JSON.stringify(setup),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        // keepalive: true,
      },
    )
    return { success: true }
  }
  catch (_) {
    return { success: false }
  }
}

export const queue = new PushQueue('setup', pushSetup)

export const push = queue.push.bind(queue)
