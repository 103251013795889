import type { RecordingSetup } from '@/types/recorder'
import type { OnLoadArguments, SetupArguments } from '@/types/setup'
import { createRecorderObservable } from '@/modules/recorder/recorder'
import { DOMSerializer } from '@/util/domSerializer'
import { makeBufferedObservable } from '@/util/rxjs'

function getLoadTimings() {
  return new Promise<PerformanceEntry>((resolve) => {
    const observer = new PerformanceObserver((list) => {
      resolve(list.getEntries()[0])
    })
    observer.observe({ type: 'navigation', buffered: true })
  })
}

export async function onSetupEvent({
  window,
  document,
  domSerializer = new DOMSerializer(),
  startTimestamp,
  pingPromise,
}: SetupArguments): Promise<RecordingSetup> {
  const [resolvedPing, resolvedTimings] = await Promise.all([pingPromise, getLoadTimings()])

  return {
    timestamp: startTimestamp,
    styles: [], // these are not being used
    dom: domSerializer.serializeNode(document.documentElement)!,
    url: window.location.href,
    referrer: document.referrer,
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    browser: {
      userAgent: window.navigator.userAgent,
      hardwareConcurrency: window.navigator.hardwareConcurrency,

      doNotTrack: window.navigator.doNotTrack,
      referrer: document.referrer,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      availableScreenSize: `${window.screen.availWidth}x${window.screen.availHeight}`,
      colorDepth: window.screen.colorDepth,

      // @ts-expect-error - userLanguage is non-standard
      browserLanguage: window.navigator.language || (window.navigator.userLanguage),
      timezoneOffset: new Date().getTimezoneOffset(),
      cookiesEnabled: window.navigator.cookieEnabled,
      external: resolvedPing,
    },
    loadTimings: resolvedTimings,
  }
}

export async function onSessionLoad({ window, document, domSerializer, config, sessionEnding$ }: OnLoadArguments) {
  const recorder$ = createRecorderObservable(
    window,
    document,
    domSerializer,
    config,
  )

  // Emit the first four events in the first buffer
  // This ensures that we get something from every recording sooner instead of waiting for buffering to complete
  // const bufferedRecorder$ = makeBufferedObservable(recorder$, sessionEnding$)

  // Friendly little log message that we should probably remove eventually
  // startBuffering$.subscribe(() => {
  //   // console.log('Buffering started')
  // })
  // Set up the global state
  // if (config.local) {
  //   recorder$.subscribe((x) => {
  //     // I leave these here not expose()'d because it just makes more sense to read
  //     // as an array vs a function call
  //     // and the push makes sense
  //     // @ts-expect-error - this is fine
  //     window._nlytics_events = window._nlytics_events || []
  //     // @ts-expect-error - this is fine
  //     window._nlytics_events.push(x)
  //   })
  // }

  // We want to pass observables here, not subscriptions. Subscriptions happen later
  return {
    recorder$: makeBufferedObservable(recorder$, sessionEnding$),
  }
}
