// This is a utility function to convert a string to a Uint8Array
function str2ab(str: string) {
  const buf = new Uint8Array(str.length) // 2 bytes for each char
  // const bufView = new Uint16Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++)
    buf[i] = str.charCodeAt(i)

  return buf
}

// This is a polyfilled utility function to convert a string to a Uint8Array
export function encodeToUint8Array(str: string) {
  if (typeof TextEncoder === 'function')
    return new TextEncoder().encode(str)
  else return str2ab(str)
}
