import { Observable } from 'rxjs'

class ElementWaiter {
  mutationObserver?: MutationObserver
  callbacks: Map<string, Set<(element: Element) => void>> = new Map()
  connected: boolean

  constructor() {
    this.callbacks = new Map()
    this.makeObserver()
    this.connected = false
  }

  makeObserver() {
    const callbacks = this.callbacks
    this.mutationObserver = new MutationObserver(() => {
      for (const [selector, cbs] of callbacks.entries()) {
        const element = document.querySelector(selector)
        if (element) {
          callbacks.delete(selector)
          for (const callback of cbs)
            callback(element)
        }
      }
      if (callbacks.size === 0) {
        this.mutationObserver!.disconnect()
        this.connected = false
      }
    })

    // console.log(document.body)
    if (!document.body) {
      window.setTimeout(this.makeObserver, 500)
      return
    }

    this.mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    })
    this.connected = true
  }

  waitFor(selector: string): Observable<Element> {
    return new Observable((observer) => {
      const element = document.querySelector(selector)
      if (element) {
        observer.next(element)
        observer.complete()
        return
      }

      const callbacks = this.callbacks.get(selector) || new Set()
      callbacks.add((element) => {
        observer.next(element)
        observer.complete()
      })
      this.callbacks.set(selector, callbacks)

      if (!this.connected)
        this.makeObserver()
    })
  }
}

let elementWaiter: ElementWaiter | null = null
export function getElementWaiter() {
  if (!elementWaiter)
    elementWaiter = new ElementWaiter()
  return elementWaiter
}

// export function waitForEl(selector: string): Observable<Element> {
//   return new Observable((observer) => {
//     const element = document.querySelector(selector)
//     if (element) {
//       observer.next(element)
//       observer.complete()
//       return
//     }

//     const mutationObserver = new MutationObserver(() => {
//       const element = document.querySelector(selector)
//       if (element) {
//         mutationObserver.disconnect()
//         observer.next(element)
//         observer.complete()
//       }
//     })

//     mutationObserver.observe(document.body, {
//       childList: true,
//       subtree: true,
//     })

//     // Handle unsubscription
//     return () => mutationObserver.disconnect()
//   })
// }
