enum MutationType {
  Attributes = 0, // 'attributes',
  CharacterData = 1, // 'characterData',
  ChildList = 2, // 'childList',
}

// id, nodeName, attributes, children?
// id, nodeValue
type NodePosition = [previousSibling: number, nextSibling: number]
export interface SerializedAttributes {
  [key: string]: string
}
export type SerializedElement =
  | readonly [id: number, string, SerializedAttributes, SerializedNode[], NodePosition?]
  | readonly [id: number, string, SerializedAttributes, NodePosition?]
export type SerializedTextNode = readonly [id: number, content: string, NodePosition?]
export type SerializedNode = SerializedElement | SerializedTextNode

export type SerializedChildListMutation = readonly [
  number,
  MutationType.ChildList,
  readonly SerializedNode[],
  readonly number[],
  any?,
]
export type SerializedCharacterDataMutation = readonly [
  number,
  MutationType.CharacterData,
  string,
  any?,
]
export type SerializedAttributesMutation = readonly [
  number,
  MutationType.Attributes,
  string,
  string,
  any?,
]
export type SerializedMutation =
  | SerializedChildListMutation
  | SerializedCharacterDataMutation
  | SerializedAttributesMutation

interface NtagNode extends Node {
  ntagId?: number
}
export class DOMSerializer {
  private currentId: number
  private nodeToId: WeakMap<Node, number>
  constructor() {
    this.currentId = 0
    this.nodeToId = new WeakMap()
  }

  private indexNode(node: NtagNode) {
    const id = this.currentId++
    this.nodeToId.set(node, id)
    node.ntagId = id
    return id
  }

  public getNodeId(node: NtagNode) {
    // If node already indexed, return existing ID, else index the node
    return (
      node.ntagId
      || (this.nodeToId.has(node)
        ? this.nodeToId.get(node)!
        : this.indexNode(node))
    )
  }

  public serializeNode(
    node: Node,
    removeTextNodes = false,
  ): SerializedNode | null {
    // Filter out bad elements
    // if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE')
    //   return null
    if (node.nodeType === Node.COMMENT_NODE || node.nodeName === 'SCRIPT')
      return null

    const id = this.getNodeId(node)

    // Special handling for text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      if (
        !node.nodeValue
        || (removeTextNodes && /^\s+$/.test(node.nodeValue))
      ) {
        return null
      }
      else {
        return [id, node.nodeValue.replace(/\s+/, ' ')] as const
      } // [id, nodeValue]
    }

    // Special handling for SVG elements
    if (node instanceof SVGElement)
      return [id, node.outerHTML] // [id, SVG as string]

    // const serialized: readonly [
    //   number,
    //   string,
    //   { [key: string]: string },
    //   any[],

    // ] = [id, node.nodeName, {}, []] // ["id", "nodeName", {attributes}?, [children]?]

    const attributes: SerializedAttributes = {}
    const children: SerializedNode[] = []
    const position: NodePosition = [node.previousSibling ? this.getNodeId(node.previousSibling) : -1, node.nextSibling ? this.getNodeId(node.nextSibling) : -1]
    // Serialize attributes
    if (
      node instanceof Element
      && node.attributes
      && node.attributes.length
    ) {
      // serialized[] = {} // Add attributes object
      for (const attr of node.attributes) {
        // Adjust this list of attributes as needed
        // if (
        //   [
        //     'id',
        //     'class',
        //     'name',
        //     'value',
        //     'type',
        //     'href',
        //     'rel',
        //     'charset',
        //     'dir',
        //     'integrity',
        //     'src',
        //     'onload',
        //     'async',
        //     'crossorigin',
        //     '',
        //   ].includes(attr.name)
        // )
        attributes[attr.name] = attr.value
      }
    }

    for (const child of node.childNodes) {
      const serializedChild = this.serializeNode(
        child,
        removeTextNodes || node.nodeName === 'HEAD',
      ) // remove text nodes of all HEAD descendants
      if (serializedChild)
        children.push(serializedChild) // Add serialized child to children array
    }

    return [id, node.nodeName, attributes, children, position] as const
  }

  public serializeMutation(mutation: MutationRecord): SerializedMutation {
    const targetId = this.getNodeId(mutation.target)
    const addedNodes = Array.from(mutation.addedNodes)
      .map(node => this.serializeNode(node))
      .filter((node): node is SerializedNode => node !== null)
    const removedNodes = Array.from(mutation.removedNodes).map(
      this.getNodeId.bind(this),
    )

    switch (mutation.type) {
      case 'attributes':
        // Calmate, this is going to have attributes
        return [
          targetId,
          MutationType.Attributes,
          mutation.attributeName as string,
          (mutation.target as Element).getAttribute(
            mutation.attributeName as string,
          ) as string,
          {
            nodeName: mutation.target.nodeName,
            type: mutation.target.nodeType,
          },
        ] as const
      case 'characterData':
        return [
          targetId,
          MutationType.CharacterData,
          mutation.target.nodeValue as string,
          {
            nodeName: mutation.target.nodeName,
            type: mutation.target.nodeType,
          },
        ] as const
      case 'childList': {
        const nextSibling = mutation.nextSibling
          ? this.getNodeId(mutation.nextSibling)
          : null
        const previousSibling = mutation.previousSibling
          ? this.getNodeId(mutation.previousSibling)
          : null
        return [
          targetId,
          MutationType.ChildList,
          addedNodes,
          removedNodes,
          {
            nodeName: mutation.target.nodeName,
            type: mutation.target.nodeType,
            nextSibling,
            previousSibling,
          },
        ] as const
      }
    }
  }
}
