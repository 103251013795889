import type {
  RecordingEventByActionType,
} from '../types'

import { of } from 'rxjs'
import { getTimestamp } from '../helpers'
import { ActionType } from '../types'

export function createFirstEventObservable({ window, document }: { window: Window, document: Document }) {
  // There is an initial event that is used to set up the recording
  // Since the window and stuff fall under this, we need to at least say what the screen size is
  return of<[RecordingEventByActionType<ActionType.SCREEN_EVENT>, RecordingEventByActionType<ActionType.SCROLL_EVENT>]>({
    timestamp: getTimestamp(),
    type: ActionType.SCREEN_EVENT,
    data: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  }, {
    timestamp: getTimestamp(),
    type: ActionType.SCROLL_EVENT,
    data: {
      target: null,
      x: document.documentElement.scrollLeft,
      y: document.documentElement.scrollTop,
    },
  })
}
