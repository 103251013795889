import { GEOLOCATE_API_ENDPOINT, REST_API_ENDPOINT } from '@/const'

export function fetchJWT(key: string): Promise<string> {
  const tokenPromise = fetch(new URL('public/token', REST_API_ENDPOINT), {
    method: 'POST',
    body: JSON.stringify({
      token: key,
    }),
  })
    .then(res => res.json())
    .then(res => res.jwt)

  tokenPromise.then((token) => {
    localStorage.setItem(`_n_token_${key}`, token)
  })

  return tokenPromise
}

export function isTokenValid(token: string | null) {
  if (!token)
    return false

  const parts = token.split('.')
  if (parts.length !== 3)
    return false

  try {
    for (const part of parts.slice(0, 2)) {
      JSON.parse(atob(part))
    }
  }
  catch {
    return false
  }

  const { exp } = JSON.parse(atob(token.split('.')[1]))
  return Date.now() / 1000 < exp
}

export function fetchPing() {
  return fetch(GEOLOCATE_API_ENDPOINT)
    .then(x => x.json())
    .catch(() => fetch('https://ipapi.co/json/')
      .then(x => x.json())
      .catch(() => fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then(x => x.text())
        .then(x => Object.fromEntries(
          x.split('\n').map(x => x.split('=')),
        ),
        ),
      )
      .catch(() => null),
    )
}

export function fetchMetrics({ key, pageUrl, sessionId, visitorId }: { key: string, pageUrl: string, sessionId: string, visitorId: string }) {
  return fetch(
    new URL(
      `public/events/${key}/metrics?${new URLSearchParams({
        pageUrl,
        sessionId,
        visitorId,
      })}`,
      REST_API_ENDPOINT,
    ),
  )
    .then(x => x.json())
    .then(x => x.data)
    .catch(e => console.error(e, 'Ntag metrics fetch failed'))
}
