// import process from 'node:process'

type LogLevel = 'info' | 'warn' | 'error' | 'debug'

for (const key in console) {
  (console as any)[`_${key}`] = (console as any)[key as keyof Console]
}

/**
 * Environment variables.
 */
const NODE_ENV = import.meta.env?.MODE ?? (typeof process !== 'undefined' && process.env && process.env.NODE_ENV) // eslint-disable-line node/prefer-global/process
const LOG_LEVEL: LogLevel = (localStorage.getItem('LOG_LEVEL') as LogLevel) || (NODE_ENV === 'production' ? 'error' : 'debug')

/**
 * Log levels and their order.
 */
const LEVELS: Record<LogLevel, number> = {
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
}

/**
 * Log level styles for formatting.
 */
const STYLES: Record<LogLevel, string> = {
  debug: 'color: #9AA2AA',
  info: 'color: #659AD2',
  warn: 'color: #F9C749',
  error: 'color: #EC3D47',
}

/**
 * Log level methods fallback.
 */
const METHODS: Record<LogLevel, string> = {
  // @ts-expect-error using old fallbacks
  debug: console.debug ? 'debug' : 'log', // eslint-disable-line no-console
  // @ts-expect-error using old fallbacks
  info: console.info ? 'info' : 'log', // eslint-disable-line no-console
  // @ts-expect-error using old fallbacks
  warn: console.warn ? 'warn' : 'log',
  // @ts-expect-error using old fallbacks
  error: console.error ? 'error' : 'log',
}

interface LoggerConfig {
  level: LogLevel
  prefix: string
  threshold: number
}

class Logger {
  private config: LoggerConfig

  constructor(options: Partial<LoggerConfig> = {}) {
    const { level = LOG_LEVEL, prefix = '' } = options

    const normalizedLevel = this.normalizeLevel(level)

    this.config = {
      level: normalizedLevel,
      prefix: String(prefix),
      threshold: LEVELS[normalizedLevel],
    }
  }

  /**
   * Normalize log level.
   */
  private normalizeLevel(level: string): LogLevel {
    const normalizedLevel = level.toLowerCase()
    return LEVELS[normalizedLevel as LogLevel] ? (normalizedLevel as LogLevel) : 'info'
  }

  /**
   * Log to the console with `level` and all arguments passed.
   */
  log(level: LogLevel, ...args: unknown[]): void {
    const { threshold, prefix } = this.config
    const value = LEVELS[level]

    if (value < threshold)
      return

    const method = METHODS[level] as keyof Console
    const styledPrefix = `%c[${level}] ${prefix}`
    const style = STYLES[level];

    // Pass the styled prefix followed by all other arguments
    (console as any)[method](styledPrefix, style, ...args)
  }

  debug(...args: unknown[]): void {
    this.log('debug', ...args)
  }

  info(...args: unknown[]): void {
    this.log('info', ...args)
  }

  warn(...args: unknown[]): void {
    this.log('warn', ...args)
  }

  error(...args: unknown[]): void {
    this.log('error', ...args)
  }

  /**
   * Create a new logger instance, extending the current logger's config.
   */
  clone(options: Partial<LoggerConfig> = {}): Logger {
    return new Logger({
      ...this.config,
      ...options,
    })
  }
}

/**
 * Create a logger singleton with sane defaults.
 */
export const logger = new Logger({
  level: LOG_LEVEL,
  prefix: '[ntag] ',
})

/**
 * Export logger class for other uses.
 */
export { Logger }
